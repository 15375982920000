import React from 'react'
import '../scss/global.scss'
import {
    ourImpact,
    container,
    grid,
    grid__column,
    grid__column__icon,
    impactIcon
} from "../scss-modules/our-impact.module.scss";
import SectionIntro from "./SectionIntro";

//Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUsers, faAward, faPiggyBank, faHandshake} from '@fortawesome/free-solid-svg-icons'


export default function OurImpact() {
    return (
        <section id="ourImpact" className={`${ourImpact} white-text padding-full`}>
            <div className={`${container} container`}>

                <SectionIntro>
                    <h2>Our impact</h2>
                    <p>Creating bespoke solutions for each customer to enhance performance.</p>
                </SectionIntro>

                <div className={`${grid} grid`}>

                    <div className={grid__column}>
                        <div className={grid__column__icon}>
                            {/*<FontAwesomeIcon icon={faUsers} className={impactIcon}/>*/}
                            <img src={'../../icons/font-awesome/trusted-networks.svg'} alt={'Trusted Networks'} />
                        </div>
                        <h3>Trusted Networks</h3>
                        <p>A proven global network of equipment suppliers and local / expatriate workforces. Offering
                            clients competency assurance on their projects.</p>
                    </div>

                    <div className={grid__column}>
                        <div className={grid__column__icon}>
                            <img src={'../../icons/technical-knowledge_icon.svg'} alt="Technical Knowledge"/>
                        </div>
                        <h3>Technical Knowledge</h3>
                        <p>Our specialists hold in-depth technical knowledge across the Energy Sector. Supporting the
                            Improvement of competency verification, communication, & HSE standards.</p>
                    </div>

                    <div className={grid__column}>
                        <div className={grid__column__icon}>
                            <img src={'../../icons/cost-saving_icon.svg'} alt="Cost Saving"/>
                        </div>
                        <h3>Cost Saving</h3>
                        <p>Adaptable solutions for each individual client, reducing NPT, CAPEX & OPEX expenditure.</p>
                    </div>

                    <div className={grid__column}>
                        <div className={grid__column__icon}>
                            <img src={'../../icons/partnership_icon.svg'} alt="Partnership"/>
                        </div>
                        <h3>Partnership</h3>
                        <p>We invest time learning our clients business models & services. Providing a cohesive approach
                            as an extension of their business.</p>
                    </div>

                </div>

            </div>
        </section>
    )
}
