// extracted by mini-css-extract-plugin
export var container = "our-impact-module--container--9d887";
export var grid = "our-impact-module--grid--2d59d";
export var gridV2 = "our-impact-module--grid-v2--24b79";
export var gridV2__column = "our-impact-module--grid-v2__column--138ad";
export var gridV2__column__icon = "our-impact-module--grid-v2__column__icon--941b6";
export var grid__column = "our-impact-module--grid__column--a0ffc";
export var grid__column__icon = "our-impact-module--grid__column__icon--b6631";
export var impactIcon = "our-impact-module--impact-icon--6ed23";
export var ourImpact = "our-impact-module--our-impact--e6a08";
export var ourImpactV2 = "our-impact-module--our-impact-v2--b1992";