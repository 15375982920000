import React from 'react';
import {Link} from 'gatsby';
import "../scss/global.scss";
import {grid, grid__column, grid__column__text} from '../scss-modules/sector-grid.module.scss';

export default function KeySectorsGrid() {
    return (
        <div className={grid}>
            <Link to={'/key-sectors/oil-and-gas'}>
                <div className={`${grid__column}`}>
                    <img src={'../../ctas/oil-and-gas_cta.jpg'} alt={'Oil and Gas recruitment'}/>
                    <div className={grid__column__text}>
                        <h3>Oil & Gas</h3>
                        <span className="button primary inline arrow-light"><span>View more</span></span>
                    </div>
                </div>
            </Link>

            <Link to={'/key-sectors/renewable-energy'}>
                <div className={`${grid__column}`}>
                    <img src={'../../ctas/renewable-energy_cta.jpg'} alt={'Renewable Energy recruitment'}/>
                    <div className={grid__column__text}>
                        <h3>Renewable Energy</h3>
                        <span className="button inline primary arrow-light"><span>View more</span></span>
                    </div>
                </div>
            </Link>

            <Link to={'/key-sectors/alternative-energy'}>
                <div className={`${grid__column}`}>
                    <img src={'../../ctas/alternative-energy_cta.jpg'} alt={'Alternative Energy recruitment'}/>
                    <div className={grid__column__text}>
                        <h3>Alternative Energy</h3>
                        <span className="button inline primary arrow-light"><span>View more</span></span>
                    </div>
                </div>
            </Link>

        </div>
    )
}
